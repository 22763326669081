import React from "react";
import dataFeatures from "../../data/About/about-corporate-business.json";
import FeatureBoxTwo from "../../elements/FeatureBox/FeatureBoxTwo";
import HeadingSection from "../../components/HeadingSection/HeadingSection";

const Features = (dark, id) => (
    <section className={"section" + (dark ? " section-dark" : "")}>
      <div className="container" id='section2'>
        <HeadingSection title="Наші переваги"/>
        <div className="row mt-50">
          {dataFeatures.map((feature) => (
            <FeatureBoxTwo key={feature.id} icon={feature.icon} title={feature.title}>
              {feature.text}
            </FeatureBoxTwo>
          ))}
        </div>
      </div>
    </section>
  );
  
  export default Features;