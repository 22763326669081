import React from "react";
import Icofont from "react-icofont";

const SocialItem = ({ icon }) => (
  <li>
    <a href="tel:[+380507698486]">
      <Icofont icon={icon} />
      <span className="phone-number-top" style={{fontSize:'15px'}}>+38 (050) 769 84 86</span>
    </a>
  </li>
);

export default SocialItem;
