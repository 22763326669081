import React from 'react';
import teamData from "../../data/Team/team-data.json";
import HeadingSection from '../../components/HeadingSection/HeadingSection';
import Icofont from 'react-icofont';

const OurTeamThree = () => (
    <section className="white-bg">
    <div className="container" id='section3'>
      <div className="row">
        <HeadingSection title="Наша команда" tagline="City Brokers – це команда професіоналів страхової галузі. У нас понад 20 років практичного досвіду роботи в корпоративному сегменті в структурах страхових компаній, локальних та міжнародних страхових брокерів. Частиною нашої команди є не лише професійні страхові брокери, а й адвокати, професіонали у сфері страхового врегулювання, у тому числі на міжнародних ринках" />
      </div>
      <div className="row mt-50">
        {teamData.map((member) => (
          <div style={{height:'500px'}} className="col-md-3 col-sm-6 our-team city-team" key={member.id}>
            <div className="member text-center">
              <div style={{maxHeight:'336px'}} className="team-member-container">
                <img
                  src={require("../../assets/images/" + member.image)}
                  className="img-responsive"
                  alt="team-01"
                />
                <div className="member-caption">
                  <div className="member-description text-center">
                    <div className="member-description-wrap">
                      <ul className="member-icons">
                        {member.social.map((item) => {
                          return (
                            <li className="social-icon" key={item.id}>
                              <a href={item.link}>
                                <Icofont icon={item.icon} />
                              </a>
                            </li>
                          );
                        })}
                        <div style={{color:'white'}}>
                          {member.desc}
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="member-title">{member.name}</h4>
              <p className="member-subtitle">{member.role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>

);

export default OurTeamThree;