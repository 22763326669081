import React from "react";
import logoFooter from "../../assets/images/logo_white.png";
import FooterCopyright from "./FooterCopyright";
import star from "../../assets/images/zirka.png";
import certificate from "../../assets/images/city-star.png"
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FooterOne = () => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <footer className="footer" id="footer-fixed">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <div className="widget widget-text">
                  <div className="logo logo-footer">
                    <a href={`${process.env.PUBLIC_URL}/`}>
                      <img
                        className="logo logo-display"
                        src={logoFooter}
                        alt=""
                      />
                    </a>
                  </div>
                  <p>
                    Успішне рішення – захист Ваших ризиків від команди Сіті Брокерс!
                  </p>
                  <div className="star-image" onClick={handleClickOpen}>
                    <img
                      style={{height:'125px'}}
                      className=""
                      src={star}
                      alt=""
                    />
                  </div>
                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <img src={certificate} alt=""/>
                  </Dialog>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="widget widget-links">
                  
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="widget widget-text widget-links">
                  <h5 className="widget-title">Контакти</h5>
                  <ul>
                    <li>
                      <i className="icofont icofont-google-map"></i>
                      <a href="https://www.google.com/maps/place/ул.+Соломенская,+3Б,+Киев,+02000/@50.42961,30.4733303,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4cec08fefcd7d:0xb04b8e8123711278!8m2!3d50.42961!4d30.475519">Київ, вул. Солом’янська 3б, офіс 218</a>
                    </li>
                    <li>
                      <i className="icofont icofont-iphone"></i>
                      <a href="tel:[+380507698486]">+38 (050) 769 84 86</a>
                    </li>
                    <li>
                      <i className="icofont icofont-mail"></i>
                      <a href="mailto:info@citybrokers.com.ua">info@citybrokers.com.ua</a>
                    </li>
                    <li>
                      <i className="icofont icofont-globe"></i>
                      <a href="citybrokers.com.ua">www.citybrokers.com.ua</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterCopyright />
      </footer>
      <div className="footer-height" style={{ height: "463px" }}></div>
    </div>
);
}

export default FooterOne;
