import React, {useState} from "react";

const ContactFormTwo = ({ title }) => {
  const [inputs, setInputs] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onClick = () => {
      window.Email.send({
        Host : "mail.adm.tools",
        Username : "info@citybrokers.com.ua",
        Password : "d23nYMuNbZ29",
        To : 'info@citybrokers.com.ua',
        From : "info@citybrokers.com.ua",
        Subject : "Запрос на консультацию",
        Body : `Ім’я: ${inputs.name} Email клієнта: ${inputs.email} Статус страхового випадку та сума збитку: ${inputs.message}`
    }).then(
      () => alert('Запит успішно відправлено')
    );
  }

  return (
    <form
      name="contact-form"
      className="contact-me"
      id="contact-form"
      action="php/contact.php"
      method="POST"
    >
      <div className="messages"></div>
      <div className="form-group">
        <label className="sr-only" htmlFor="name">
          Ваше ім’я
        </label>
        <input
          type="text"
          name="name"
          className="form-control"
          id="name"
          required="required"
          placeholder="Ваше ім’я"
          data-error="Це поле обов’язкове"
          value={inputs.name}
          onChange={handleInputChange}
        />
        <div className="help-block with-errors mt-20"></div>
      </div>
      <div className="form-group">
        <label className="sr-only" htmlFor="email">
           Ваш Email
        </label>
        <input
          type="email"
          name="email"
          className="form-control"
          id="email"
          placeholder="Ваш Email"
          required="required"
          data-error="Будь ласка, введіть коректний Email"
          value={inputs.email}
          onChange={handleInputChange}
        />
        <div className="help-block with-errors mt-20"></div>
      </div>
      <div className="form-group">
        <label className="sr-only" htmlFor="message">
          Статус страхового випадку та сума збитку
        </label>
        <textarea
          name="message"
          className="form-control"
          id="message"
          rows="2"
          placeholder="Статус страхового випадку та сума збитку"
          required
          data-error="Будь ласка, опишіть статус страхового випадку та сума збитку"
          value={inputs.message}
          onChange={handleInputChange}
        ></textarea>
        <div className="help-block with-errors mt-20"></div>
      </div>
      <div className="text-center">
        <button
          type="button"
          name="submit"
          className="btn btn-color btn-circle"
          onClick={onClick}
        >
          Отримати консультацію
        </button>
      </div>
    </form>
  );
};

export default ContactFormTwo;
