import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import CorporateBusiness from "./pages/home/CorporateBusiness";
import Page404 from "./pages/others/Page404";

function App() {

  return (
    <Router basename={"/"}>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={CorporateBusiness} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/404-page`}
          component={Page404}
        />
        <Route component={Page404} />
      </Switch>
    </Router>
  );
}

export default App;
