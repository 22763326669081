import React from "react";
import logo from "../assets/images/logo_black.png";
import Icofont from "react-icofont";
import { Link } from "react-scroll";

const MainLogo = ({ collapse, showMenu }) => {

  return (
    <div className="navbar-header" id='section5'>
        <button
          type="button"
          className={"navbar-toggle " + (collapse ? "collapsed" : "")}
          data-toggle="dropdown"
          data-target="#navbar-menu"
          onClick={showMenu}
        >
          <Icofont icon="navigation-menu" />
        </button>
        <div className="logo">
          <Link
            style={{cursor:'pointer'}}
            activeClass="active"
            to="section5"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav-item-link"
          >
            <img className="logo" src={logo} alt="logo" />
          </Link>
        </div>
    </div>
)};


export default MainLogo;
