import React from "react";
import Loader from "./../../components/Loader/Loader";
import dataCorporate from "../../data/Slider/corporate-business-data.json"
import HeroSliderSingle from "../../components/Hero/HeroSliderSingle";
import WhoWeAreThree from "../../components/WhoWeAre/WhoWeAreThree";
import FooterOne from "../../components/Footer/FooterOne";
import HeaderTwo from "../../components/Header/HeaderTwo";
import OurTeam from "../../components/Team/OurTeamThree";
import ClientsBrand from "../../components/ClientsBrand/ClientsBrand";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import Features from "../../components/WhoWeAre/Features";

const CorporateBusiness = () => (
  <Loader>
    <div
      title="Section 5"
      subtitle="some"
      id="section5"
    ></div>
    <HeaderTwo />
    <HeroSliderSingle data={dataCorporate} />
    <WhoWeAreThree 
      title="Section 1"
      subtitle="some"
      id="section1"
    />
    <Features
      title="Section 2"
      subtitle="some"
      id="section2"
    />
    <OurTeam 
      title="Section 3"
      subtitle="some"
      id="section3"
    />
    <ClientsBrand classAppend="mt-50"
      title="Section 4"
      subtitle="some"
      id="section4"
    >
      <HeadingSection title="Наші партнери"/>
    </ClientsBrand>
    <FooterOne />
  </ Loader>
);

export default CorporateBusiness;
