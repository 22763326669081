import React, { useState } from "react";
import ReactWOW from "react-wow";

const ContactForm = ({ title, tagline }) => {
  const [inputs, setInputs] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const onClick = () => {
      window.Email.send({
        Host : "mail.adm.tools",
        Username : "info@citybrokers.com.ua",
        Password : "d23nYMuNbZ29",
        To : 'info@citybrokers.com.ua',
        From : "info@citybrokers.com.ua",
        Subject : "Партнерский запрос",
        Body : `Назва компанії: ${inputs.name} Email компанії: ${inputs.email}`
    }).then(
      () => alert('Запит успішно відправлено')
    );
  }

  return (
    <>
      {title && (
        <ReactWOW animation="fadeTop" delay="0.1s">
          <h2 className="text-uppercase font-700">{title}</h2>
        </ReactWOW>
      )}
      {tagline && (
        <ReactWOW animation="fadeTop" delay="0.1s">
          <h4 className="text-uppercase">- {tagline} -</h4>
        </ReactWOW>
      )}
      <form
        name="contact-form"
        id="contact-form"
        action="php/contact.php"
        method="POST"
        className="mt-50"
      >
        <div className="messages"></div>
        <ReactWOW animation="fadeTop" delay="0.1s">
          <div className="form-group">
            <label className="sr-only" htmlFor="name">
              Назва компанії
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              id="company-name"
              required="required"
              placeholder="Назва компанії"
              data-error="Будь ласка, вкажіть назву компанії"
              value={inputs.name}
              onChange={handleInputChange}
            />
            <div className="help-block with-errors mt-20"></div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeTop" delay="0.2s">
          <div className="form-group">
            <label className="sr-only" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              placeholder="Email"
              required="required"
              data-error="Будь ласка, вкажіть Email"
              value={inputs.email}
              onChange={handleInputChange}
            />
            <div className="help-block with-errors mt-20"></div>
          </div>
        </ReactWOW>
        <ReactWOW animation="fadeTop" delay="0.4s">
          <button
            type="button"
            name="submit"
            className="btn btn-color btn-circle"
            onClick={onClick}
          >
            Стати партнером
          </button>
        </ReactWOW>
      </form>
    </>
  );
};

export default ContactForm;
